export default class Login {
  loginId: string;
  password: string;
  selectTab: number;
  isQr: boolean;

  constructor(id: string, password: string, selectTab = 0, isQr = false) {
    this.loginId = id;
    this.password = password;
    this.selectTab = selectTab;
    this.isQr = isQr;
  }
}
